export default {
    title: 'G2mail',
    view_details: '商品详情',
    business: '平台商务',
    channels: '平台频道',
    total: '总数：',
    page_size: '条/页',
    url_title: 'Email验证码',
    used_title: '二手邮箱',
    mailbox_title: 'Email帐号',
    oauth_title: '邮箱授权',
    notice: '公告',
    price: '单价',
    console: '个人中心',
    create_account: '创建账号',
    user: '账号',
    password: '密码',
    role_admin: '管理员',
    role_user: '用户',
    quit: '退出',
    buy_count: '购买数量',
    buy_count_msg: '请输入购买数量',
    buy: '购买',
    business_type: '业务类型：',
    mailbox_type: '邮箱类型：',
    bill_price: '商品单价：',
    bill_buy_count: '购买数量：',
    pay_amount: '支付金额：',
    confirm_order: '确认订单',
    cancel: '取 消',
    pay: '支 付',
    login: '登录',
    reset: '重置密码',
    sign_up: '注册',
    confirm_pass: '确认密码',
    email: '邮箱',
    email_code: '邮箱验证码',
    send_code: '发送验证码',
    submit: '提 交',
    stock: '库存',
    create_time: '创建时间',
    query: '查询',
    start_time: '开始时间',
    end_time: '结束时间',
    username: '用户',
    remark: '备注',
    recharge_title: '充值',
    recharge_amount: '充值金额',
    recharge_tip: `<div style="margin-bottom: 5px">1. <b>最终充值金额</b>以支付页面为准，付款时请注意<b>小数位的金额</b>，金额错误将无法自动到账，需联系客服。</div><div>2. 付款超时时间为<b>15分钟</b>，超时请勿付款，若超时后付款，请联系客服处理。</div>`,
    type_detail_1: '谷歌邮箱API - 接收任何',
    type_detail_2: '的邮件信息',
    delimiter: '分隔符(默认为"----")',
    email_front: '邮箱在前',
    email_after: '邮箱在后',
    used_email: '二手邮箱',
    detail: '商品说明',
    oauth_detail_1: '谷歌邮箱授权 - ',
    oauth_detail_2: '授权登录',
    other: `任何关于Gmail邮箱项目都可以联系<a href="https://t.me/G2linghangyuan1" target="_blank" class="link">平台商务</a>对接！`,
    footer_desc: '临时电子邮件是一种专为短期电子邮件接收而设计的工具。它允许用户快速生成一个临时电子邮件地址，以接收验证电子邮件或其他非永久性电子邮件，而无需注册。',
    url_detail: ` <li>默认链接格式为: http://0.0.0.0:9060/mail/code?key=pY7CQ4Mo1b%252FNX4YwP6KpWcineJfTbKdCZN795CufMoV1DGhsOST%252BctZm%252BeE7%252FtxwPyYxatTh8KXWy3iyK3%252B9sjMVgcmUtBc1hMzGqsGnasfKdxbC2C8iR5HsYYY1vMLq&fd=test@g2mail.shop----xxxxxxxxxx@gmail.com</li>
        <li>xxxxxxxxxx@gmail.com 为邮箱地址</li>
        <li>自定义格式需要删除分隔符和邮箱地址才能请求</li>
        <li>已收到验证码返回格式: <br />{
          "email_code": "888888",
          "content": "Test code is 888888\\n",
          "subject": "Test",
          "status": 1,
          "message": "Code received"
          }</li>`,
    used_detail: `<li>该商品为平台已经接过业务验证码的邮箱,如Telegram, Apple, Instagram等</li>
        <li>该商品由于为特殊商品，且会根据用户登陆环境触发谷歌风控，售后时间为8小时</li>
        <li><li>3:30 ~ 5:00(GMT+8) 时间段无法购买</li></li>
        <li>邮箱格式为：邮箱地址----密码----辅助邮箱</li>`,
    login_rule: {
        email_error: '邮箱格式不正确',
        no_user: '请输入账号',
        user_len_error: '长度在 6 到 18 个字符',
        user_format_error: '必须以英文字母开头',
        no_pass: '请输入密码',
        pass_len_error: '长度在 6 到 18 个字符',
        no_confirm_pass: '请输入确认密码',
        no_email_code: '请输入邮箱验证码',
        confirm_pass_error: '确认密码不一致',
    },
    home: {
        select_date: '选择日期',
        register_count: '注册总数：',
        balance: '空闲余额：',
        freeze: '锁定金额：',
        secret_key: 'API密钥：',
        create_time: '注册时间：',
        email: '邮箱：',
        freeze_tip: '正在交易的金额',
    },
    mail_link: {
        batch_id: '批次编号',
        amount: '数量',
        type: '业务类型',
        action: '操作',
        download: '下载',
        detail: '详情',
        operator: '操作人'
    },
    link_task: {
        batch_id: '批次编码',
        address: '地址',
        type: '业务类型',
        state: '状态',
        price: '单价',
        receive_time: '收码时间',
        code_time: '获取时间',
        code: '验证码',
        input_batch_id: '请输入批次编码',
    },
    api_task: {
        address: '地址',
        type: '业务类型',
        state: '状态',
        charge: '扣费',
        price: '单价($)',
    },
    oauth_task: {
        address: '地址',
        type: '授权类型',
        state: '状态',
        price: '单价($)',
    },
    capital: {
        amount: '金额',
        operator: '操作人',
        type: '类型',
        consumption: '消费：',
        recharge: '充值：'
    },
    pay_page: {
        minutes: '分',
        seconds: '秒',
        tip: '地址已复制',
        timeout1: '支付已过期',
        timeout2: '请重新发起支付',
        success: '充值成功',
        success_tip: '已支付成功',
        cancel_tip: '已取消支付',
        tip_warn: `<div>1.请注意付款金额的小数位,转账金额与页面显示金额必须完全一致</div>
          <div>2.请在倒计时结束前付款</div>
          <div>3.请仔细检查付款地址,因付款地址错误造成的损失,平台不承担任何责任</div>
          <div>4.如有任何疑问,请随时联系客服</div>`
    },
    recharge: {
        address: '收款地址',
        state: '状态',
        money: '金额($)',
        expire_time: '超时时间',
        action: '操作',
        cancel: '取消',
        confirm: '确认',
        pay: '去支付',
        tip_msg: '确认取消支付?',
        tip_title: '温馨提示'
    },
    notice_page: {
        title: '标题',
        content: '内容',
    },
    history: {
        batch_id: '批次',
        amount: '数量',
        price: '单价($)',
        action: '操作',
        download: '下载',
        type: '类型'
    },
    used_mail: {
        price: '单价($)',
        address: '地址',
        password: '密码',
        recovery: '辅助'
    },
    link_api_record: {
        id: '批次',
        amount: '金额($)',
        count: '数量',
        action: '操作',
    },
    api: {
        front: '前言',
        baseUrl: '接口地址：',
        post_content: 'Post请求数据格式：',
        response_content: '响应内容为JSON格式',
        success_code: '请求成功码',
        desc: '描述',
        params_name: '参数名',
        params_value: '参数值',
        must: '必传',
        address: '邮箱地址',
        secret_key: 'API密钥',
        must_true: '是',
        must_false: '否',
        query_balance: '查询余额',
        balance: '余额',
        lock_balance: '锁定余额',
        other: '其他',
    },
    oauth_api: {
        auth_code: '授权code',
        get_mail_oauth_result: '获取授权结果',
        get_mail_oauth_result_desc: '需要多次请求(请求间隔3秒以上)',
        oauth_type: '授权业务类型',
        oauth_url: '授权地址',
        release: '释放任务',
        release_desc: '2分钟后可请求, 在长时间没有返回结果时调用,如果不调用,锁定余额将不会被释放',
        task_id: '任务id',
        task_state: '任务状态',
        type_name: '授权业务名称',
        post_task: '提交授权任务',
        type_value: '接口传值',
    },
    used_mail_api: {
        get_mails: '获取邮箱',
        count: '获取数量(默认值为1,最大值为100)'
    },
    link_api: {
        getLink: '获取链接',
        count: '数量(最大值为500)',
        type_id: '业务ID',
        type_name: '业务名称',
        type_value: '业务ID'
    }
}