<template>
  <el-container>
    <el-header class="header">
      <i class="el-icon-menu" style="font-size: 1.5rem;color: rgba(0,0,0,0.7)" v-if="mobile"
         @click="drawer = !drawer;"></i>
      <div v-else>
        <router-link class="logo" :to="{path: '/'}">
          <img :src="logo" :alt="$t('title')" width="40">
          <b style="margin-left: 10px">{{ $t('title') }}</b>
        </router-link>
      </div>
      <div style="display: flex;align-items: center">
        <router-link :to="{path: '/'}" style="color: inherit">
          <i class="el-icon-shopping-cart-2" style="margin-right: 1.2rem;font-size: 1.5rem;"/>
        </router-link>
        <el-dropdown trigger="click" placement="bottom" @command="changeLanguage">
            <span class="el-dropdown-link" style="font-size: 0.8rem;margin-right: 1rem;cursor: pointer">
              {{ getLangName($i18n.locale) }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="zh">中文</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover
            placement="bottom-start"
            trigger="click">
          <template>
            <div class="popover-item" @click="show" v-if="$store.state?.userInfo.role >= 30">
              <i class="el-icon-user"></i> {{ $t('create_account') }}
            </div>
            <div class="popover-item" @click="logout"><i class="el-icon-switch-button"></i> {{ $t('quit') }}</div>
          </template>
          <span slot="reference" class="username">
            {{ $store.state.userInfo.username }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </el-popover>
      </div>
    </el-header>
    <div style="height: 50px"></div>
    <el-container>

      <el-drawer
          v-if="mobile"
          size="70%"
          :visible.sync="drawer"
          direction="ltr">
        <div class="logo">
          <img :src="logo" :alt="$t('title')" width="40">
          <b style="margin-left: 10px">{{ $t('title') }}</b>
        </div>
        <template v-for="(route, index) in routes[0].children">
          <template v-if="!route.roles || route.roles.includes($store.state.userInfo.role)">
            <div @click="tapLink" :key="index">
              <router-link class="nav-item" :class="[$route.name===route.name ? 'select-nav' :'']"
                           :to="{name: route.name}" v-if="!route.hide">
                <i :class="route.icon" style="margin-right: 10px"/> {{ route.meta.title[$i18n.locale] }}
              </router-link>
            </div>
          </template>
        </template>
      </el-drawer>
      <el-aside width="250px" class="aside" v-else>
        <template v-for="(route, index) in routes[0].children">
          <template v-if="!route.roles || route.roles.includes($store.state.userInfo.role)">
            <router-link class="nav-item" :class="[$route.name===route.name ? 'select-nav' :'']"
                         :key="index" :to="{name: route.name}" v-if="!route.hide">
              <i :class="route.icon" style="margin-right: 10px"/> {{ route.meta.title[$i18n.locale] }}
            </router-link>
          </template>
        </template>
      </el-aside>

      <el-main>
        <div class="main-wrapper">
          <router-view/>
        </div>
      </el-main>
    </el-container>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false"
               :width="$store.state.mobile? '80%' :'450px'">
      <div slot="title">{{ $t('create_account') }}</div>
      <el-form ref="addForm" :model="addForm" :rules="rule" label-width="0">
        <el-form-item prop="username">
          <el-input :placeholder="$t('user')" prefix-icon="el-icon-user" v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input :placeholder="$t('password')" prefix-icon="el-icon-lock" type="password"
                    v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item prop="role">
          <el-radio v-model="addForm.role" label="system" v-if="$store.state?.userInfo.role>=99">
            {{ $t('role_admin') }}
          </el-radio>
          <el-radio v-model="addForm.role" label="user">{{ $t('role_user') }}</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import {routes} from "@/router";
import logo from '../assets/logo.png'

export default {
  name: 'MainLayout',
  data() {
    return {
      drawer: false,
      mobile: false,
      logo,
      routeList: [],
      routes,
      addForm: {
        role: 'user'
      },
      dialogVisible: false,
      rule: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur'},
          {pattern: /^[a-zA-Z]/, message: '必须以字母开头', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur'},
        ],
        role: [
          {required: true, message: '请输入选择账号权限', trigger: 'blur'},
        ],
      },
    }
  },
  created() {
    this.current()
    this.mobile = document.body.clientWidth <= 768
    this.$store.commit('isMobile', this.mobile)
  },
  mounted() {
    if (this.mobile) {
      this.$store.commit('setTableHeight', '10000vh')
      this.$store.commit('setTableHeightNoPage', '10000vh')
      this.$store.commit('setTableHeightNoBtn', '10000vh')
    } else {
      this.$store.commit('setTableHeight', document.body.clientHeight - 174)
      this.$store.commit('setTableHeightNoPage', document.body.clientHeight - 134)
      this.$store.commit('setTableHeightNoBtn', document.body.clientHeight - 134)
      this.$store.commit('setPageHeight', document.body.clientHeight -93)
    }
  },
  methods: {
    current() {
      this.$axios.get('/user/current').then(res => {
        if (res.code === 0) {
          this.$store.commit('setUserInfo', res.data)
        }
      })
    },
    submit() {
      this.$refs.addForm.validate(valid => {
        if (!valid) return
        this.$axios.post('/user/system', this.addForm).then(res => {
          if (res.code === 0) {
            this.dialogVisible = false
          }
        })
      })
    },
    tapLink() {
      this.drawer = false
    },
    show() {
      this.addForm = {role: 'user'}
      this.dialogVisible = true
    },
    getLangName(lang) {
      switch (lang) {
        case 'en':
          return 'English';
        case 'zh':
          return '中文';
      }
    },
    changeLanguage(lang) {
      const current = localStorage.getItem('locale');
      if (current === lang) return
      this.$i18n.locale = lang;
      localStorage.setItem('locale', lang);
    },
    logout() {
      this.$axios.delete('/user/logout').then(() => {
      }).finally(() => {
        localStorage.removeItem('token')
        this.$store.commit('setUserInfo', {})
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style>
.el-header {
  color: #333;
  height: 50px !important;
  background: linear-gradient(135deg, #3a6186, #89253e);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-aside {
  color: #333;
  background-color: #f8f9fa;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.el-main {
  background-color: #f0f2f5;
  color: #333;
  padding: 0;
}

.logo {
  height: 40px;
  margin: 5px 0 5px 15px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.nav-item {
  height: 44px;
  font-size: 14px;
  padding-left: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin: 8px 5px;
  transition: all 0.2s ease-in-out;
}

.nav-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateX(3px);
}

.select-nav {
  background: linear-gradient(to right, #e6f7ff, #f0f7ff) !important;
  color: #1890ff;
  border-left: 3px solid #1890ff;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  background: linear-gradient(135deg, #3a6186, #89253e);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.header i, .header .el-dropdown-link {
  color: white !important;
}

.aside {
  height: calc(100vh - 50px);
  position: relative;
  z-index: 5;
}

.username {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  transition: all 0.3s;
}

.username:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.popover-item {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s;
}

.popover-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.main-wrapper {
  border-radius: 8px;
  overflow-y: auto;
  margin: 10px 10px 0 10px;
}
</style>

<style>
.el-popover {
  min-width: 0;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 6px; /* 设置滚动条宽度 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* 设置滑块背景颜色 */
  border-radius: 6px; /* 设置滑块圆角 */
}

/* 滚动条轨道hover状态样式 */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1; /* 设置轨道hover状态时的背景颜色 */
}

/* 滚动条滑块hover状态样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3); /* 设置滑块hover状态时的背景颜色 */
}

.table-header-item {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.table-header-item-icon {
  cursor: pointer;
  padding: 5px 2px;
  border-radius: 4px;
  transition: all 0.2s;
}

.table-header-item-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.self-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 6px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 36px;
  line-height: 36px;
  outline: none;
  padding: 0 15px;
  transition: all 0.3s;
  width: 100%;
}

.self-input:hover {
  border: 1px solid #1890ff;
}

.self-input:focus {
  border: 1px solid #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.table-search-wrapper {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
}

.table-search-wrapper > div {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.table-search-wrapper > div > button {
  width: 45%;
  border-radius: 6px;
  transition: all 0.3s;
}

.el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.el-dialog__header {
  background: linear-gradient(135deg, #3a6186, #89253e);
  color: white;
  padding: 16px 20px;
}

.el-dialog__title {
  color: white;
}

.el-dialog__headerbtn .el-dialog__close {
  color: white;
}

.el-dialog__body {
  padding: 20px;
}

.el-form--label-top .el-form-item__label {
  padding: 0;
}

.bg-white {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.el-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.el-table th {
  background: #f5f7fa;
}

.el-pagination {
  margin-top: 16px;
  text-align: right;
}
</style>
